/**
  Lato font
*/

/* latin-ext */
/* @font-face {
  font-family: 'Lato';
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Regular.ttf);
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
} */

/* latin-ext */
/* @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Thin.ttf);
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
} */

/* latin */
/* @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Thin.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} */

/* latin-ext */
/* @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Light.ttf);
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
} */

/* latin */
/* @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Light.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} */

/* latin-ext */
/* @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Regular.ttf);
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
} */

/* latin */
/* @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Regular.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} */

/* latin-ext */
/* @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Bold.ttf);
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
} */

/* latin */
/* @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Bold.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} */

/* latin-ext */
/* @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Black.ttf);
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
} */

/* latin */
/* @font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(../assets/fontFamily/Lato/Lato-Black.ttf);
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
} */

/**
  TheSans Font
*/

@font-face {
  font-family: 'TheSans';
  src: url(../assets/fontFamily/TheSans/TheSans-LP2ExLig.otf);
  font-weight: 200;
}

@font-face {
  font-family: 'TheSans';
  src: url(../assets/fontFamily/TheSans/TheSans-LP3Lig.otf);
  font-weight: 300;
}

@font-face {
  font-family: 'TheSans';
  src: url(../assets/fontFamily/TheSans/TheSans-LP4SeLig.otf);
  font-weight: 400;
}

@font-face {
  font-family: 'TheSans';
  src: url(../assets/fontFamily/TheSans/TheSans-LP5Plain.otf);
  font-weight: 500;
}

@font-face {
  font-family: 'TheSans';
  src: url(../assets/fontFamily/TheSans/TheSans-LP6SeBld.otf);
  font-weight: 600;
}

@font-face {
  font-family: 'TheSans';
  src: url(../assets/fontFamily/TheSans/TheSans-LP7Bld.otf);
  font-weight: 700;
}

@font-face {
  font-family: 'TheSans';
  src: url(../assets/fontFamily/TheSans/TheSans-LP8ExBld.otf);
  font-weight: 800;
}

@font-face {
  font-family: 'TheSans';
  src: url(../assets/fontFamily/TheSans/TheSans-LP9Black.otf);
  font-weight: 900;
}

@media (max-width: 1440px) and (min-width: 1081px) {
  /* HD only */
  html {
    font-size: 1.11vw;
    /* 16px at 1440px */
  }
}

@media (max-width: 1080px) and (min-width: 701px) {
  /* Desktop only */
  html {
    font-size: 1.48vw;
    /* 16px at 1080px */
  }
}

@media (max-width: 700px) {
  /* Mobile sizes */
  html {
    font-size: 14px;
    /* 16px at 700px */
  }
}

html {
  background-color: var(--ppCharcoal) !important;
  overscroll-behavior: none;
}

body {
  color: var(--darkGray);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  margin: 0;
}

.relative {
  position: relative;
}

h1,
h2,
h3,
h4 {
  /* font-family: 'Roboto', sans-serif; */
  font-weight: 700;
  padding: 0;
  margin: 0;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1rem;
}

button {
  background-color: transparent;
  outline: none;
  border: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

button:disabled,
button[disabled] {
  opacity: 0.6;
}

p {
  /* font-family: 'Open Sans', sans-serif; */
  margin: 0;
  padding: 0;
  margin-top: 6px;
  margin-bottom: 6px;
}

th,
td {
  padding: 15px;
  text-align: justify;
  font-size: 12px;
}

td.center {
  text-align: center;
}

th {
  background-color: #038f89;
  color: white;
  text-align: center;
}

a {
  font-family: 'Open Sans', sans-serif;
  text-decoration: underline;
  color: black;
}

a:hover {
  color: var(--darkGreen);
  transition: color 0.3s;
}

ol[type='1'] {
  counter-reset: item;
}

ol[type='1'] > li {
  counter-increment: item;
}

ol[type='1'] ol[type='1'] > li {
  display: block;
}

ol[type='1'] ol[type='1'] > li:before {
  content: counters(item, '.') '. ';
  margin-left: -20px;
}

.carousel-item {
  transition: transform 0.3s ease-in-out !important;
}

textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
  border-color: var(--lightYellowAlpha);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px var(--lightYellowAlpha);
  outline: 0 none;
}

input::-webkit-input-placeholder {
  text-overflow: 'ellipsis !important';
  color: var(--lightGray);
  font-size: 14px;
}

input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px var(--lightLightYellowAlpha) inset;
  transition: background-color 5000s ease-in-out 0s;
}

#charts-root {
  display: flex;
  flex-flow: row wrap;
  padding-top: 20px;
  padding-bottom: 20px;
}
.chart-width {
  width: 33.33%;
}

@media (max-width: 1200px) {
  /* Apply styles when the screen width is 768px or less */
  .chart-width {
    width: 50%; /* Set width to 50% for smaller screens */
  }
}

.tab_list {
  padding-bottom: 20px;
}

.tab_label {
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

.tab_label_active {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 3px solid var(--lightGray);
  border-bottom: 0;
}

.tab_label_inActive {
  border-bottom: 3px solid var(--lightGray);
}

.tab_list_rest {
  flex: 1;
}

.select_time_range {
  width: 130px;
  float: right;
  margin-right: 30px;
  padding-bottom: 20px;
}

.select_lead_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: start;
  }
}

.select_landing_page_url {
  width: 240px;
}
